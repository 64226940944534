export default defineNuxtRouteMiddleware(async (to) => {
	const activeOrganization = useSafeActiveOrg();
	const noActiveOrg = !activeOrganization.value;

	const toOrgSlug = to.params.orgSlug;
	const orgNotFound = toOrgSlug && noActiveOrg;

	if (!toOrgSlug || orgNotFound) {
		const { data } = await useOrgs(); // TODO: store a array of orgs in browser or query only one org per execution

		const activeOrgs = data.value?.orgs.filter((item) => new Date(item.org.validity) >= new Date());
		if (activeOrgs && activeOrgs.length > 0) {
			const firstOrg = activeOrgs[0].org;
			const firstOrgSlug = firstOrg.slug;
			return navigateTo('/app/' + firstOrgSlug);
		} else {
			return navigateTo('/');
		}
	}
});
